<template>
  <div class="min-h-full flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-12 w-auto" src="@/assets/CruzControl_Logo_Small_Icon.svg" alt="Workflow" />
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">CHANGE PASSWORD</h2>
        </div>
        <div class="mt-8" v-if="submitting">
          <div class="mt-6">
            <LoadingSpinner class="mt-10" />
          </div>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <form @submit.prevent="login" class="space-y-6">
              <div class="space-y-1">
                <div v-if="showError"  class="rounded-md bg-red-50 p-4">
                  <p class="text-sm font-regular text-red-700 animate-shake whitespace-pre break-all uppercase"> {{ errorMsg }} </p>
                </div>
                <label for="oldPassword" class="block text-sm font-medium text-gray-700 uppercase">Old Password </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <div class="relative flex flex-grow items-stretch focus-within:z-10">
                    <input id="oldPassword"
                           name="oldPassword"
                           :type="showCurrent ? 'password' : 'text'"
                           required v-model="oldPassword"
                           class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <button type="button" @click="showCurrent = !showCurrent"  class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                      <font-awesome-icon icon="fa-solid fa-eye" class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div v-if='passwordValidation.errors.length > 0' class="rounded-md bg-blue-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <font-awesome-icon icon="fa-solid fa-info-circle" class="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <div>
                      <h2>PASSWORD HINT</h2>
                      <p class="text-sm" v-for='error in passwordValidation.errors' :key="error">{{error}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="space-y-1">
                <label for="newPassword" class="block text-sm font-medium text-gray-700 uppercase">New Password </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <div class="relative flex flex-grow items-stretch focus-within:z-10">
                    <input id="newPassword"
                           name="newPassword"
                           :type="showNew ? 'password' : 'text'"
                           required
                           :disabled="!hasOldPassword"
                           v-model="newPassword"
                           class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:opacity-50">
                    <button type="button" @click="showNew = !showNew" :disabled="!oldPassword"  class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:opacity-50">
                      <font-awesome-icon icon="fa-solid fa-eye" class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="space-y-1">
                <label for="confirmPassword" class="block text-sm font-medium text-gray-700 uppercase">Confirm Password </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <div class="relative flex flex-grow items-stretch focus-within:z-10">
                    <input id="confirmPassword"
                           name="confirmPassword"
                           :type="showConfirm ? 'password' : 'text'"
                           required
                           :disabled="!newPassword"
                           v-model="confirmPassword"
                           v-on:keyup="validateConfirmPassword"
                           @blur.prevent="validateConfirmPassword"
                           class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:opacity-50">
                    <button type="button" @click="showConfirm = !showConfirm" :disabled="!newPassword" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:opacity-50">
                      <font-awesome-icon icon="fa-solid fa-eye" class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <button type="submit"
                        :disabled="!canSubmit"
                        class="w-full bg-secondary flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 uppercase disabled:hover:bg-secondary disabled:opacity-50">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-screen w-full object-cover" src="https://cruzcontrolapp.s3.us-east-2.amazonaws.com/background.jpg" alt="" />    </div>
  </div>
</template>

<script>

import { Auth } from 'aws-amplify'
import LoadingSpinner from "@/components/LoadingSpinner";
import {mapGetters, mapMutations} from "vuex";
import {store} from "@/store/store";
import {getUser} from "@/services/user.service";

export default {
  name: "FirstLoginPage",
  title: "Cruz Control | Login",
  components: {
    LoadingSpinner
  },
  data() {
    return {
      rules: [
        { message:'Contains at least 1 lowercase letter.', regex:/[a-z]+/ },
        { message:"Contains at least 1 uppercase letter.",  regex:/[A-Z]+/ },
        { message:"Contains at least 1 number.", regex:/[0-9]+/ },
        { message:"Contains at least 1 special character.", regex:/(?=.*[ -/:-@[-`{-~]{1,})+/ },
        { message:"8 characters minimum.", regex:/.{8,}/ }
      ],
      submitting: false,
      showError: false,
      errorMsg: "",
      noConnection: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      blurredNewPassword: false,
      blurredConfirmPassword: false,
      showCurrent: true,
      showNew: true,
      showConfirm: true,
    }
  },
  computed: {
    ...mapGetters(["getUser", "getEmail"]),
    passwordsFilled () {
      return (this.newPassword !== '' && this.confirmPassword !== '')
    },
    passwordValidation () {
      let errors = []
      for (let condition of this.rules) {
        if (!condition.regex.test(this.newPassword)) {
          errors.push(condition.message)
        }
      }
      if (errors.length === 0) {
        return { valid:true, errors }
      } else {
        return { valid:false, errors }
      }
    },
    hasOldPassword() {
      return this.oldPassword != ""
    },
    passwordsMatch() {
      return this.confirmPassword === this.newPassword
    },
    canSubmit(){
      return !this.submitting && this.oldPassword && this.passwordValidation.valid && this.passwordsFilled && this.passwordsMatch;
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    validateConfirmPassword () {
      if (!this.passwordsMatch && this.confirmPassword.length >= 8) {
        this.showError = true;
        this.errorMsg = "New And Confirm Password do not match"
      } else {
        this.showError = false;
      }
    },
    async login() {
      this.submitting = true
      this.showError = false
      Auth.signIn(this.getEmail, this.oldPassword)
          .then(async response => {
            // only proceed if still need new password
            if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
              // complete password reset through Cognito
              Auth.completeNewPassword(response, this.newPassword)
                .then(async () => {
                  let userData = await getUser(this.getEmail)
                  await store.commit('setUser', userData[0])
                  await this.$router.push('/')
              })
              .catch(error => {
                console.log(error)
                if(error.toString().split("LimitExceededException: ")){
                  this.errorMsg = error.toString().split("LimitExceededException: ")[1]
                  this.showError = true
                  this.submitting = false
                }
                if(error.toString().split("InvalidPasswordException: "))
                {
                  this.showError = true
                  this.errorMsg = error.toString().split("InvalidPasswordException: ")[1].split('Password does not conform to policy: ')[1]
                  this.submitting = false
                }
              })
            }
          })
          .catch(error => {
            if(error.toString().split("NotAuthorizedException: ")){
              this.errorMsg = error.toString().split("NotAuthorizedException: ")[1]
              this.showError = true
              this.submitting = false
            }
          })
    }
  },
}
</script>

<style scoped>

</style>